import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { withRouter } from 'react-router';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import Icon from 'utils/icons';

import { Avatar, Text } from 'components/DataDisplay';
import { Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { Menu } from 'components/Navigation';

import { ButtonSwitchLight } from './style';

const MenuItem = ({ text, icon }) => {
  const { themeColors } = useTheme();
  return (
    <Item alignItems="center" style={{ padding: '10px 5px' }}>
      {icon}
      <Text
        fontWeight={400}
        fontSize="14px"
        align="left"
        style={{ margin: '0 10px' }}
        color={themeColors.keep_light}
      >
        {text}
      </Text>
    </Item>
  );
};

/**
 * ProfilNav
 * @component
 *
 */
const ProfilNav = props => {
  const { toggleTheme, isLightMode } = props;
  const history = useHistory();
  const { logOut, user, isSuperAdmin } = useUser();
  const { domainConfig, domainConfigs, setDomain } = useApp();
  const { themeColors } = useTheme();

  const handleLogout = async () => {
    await logOut();
  };

  const getOptions = () => {
    return [
      {
        label: (
          <MenuItem
            text="Déconnexion"
            icon={<LogoutIcon style={{ color: themeColors.keep_light }} />}
          />
        ),
        onClick: handleLogout
      }
    ];
  };

  return (
    <Container>
      <Row alignItems="center" spacing={0}>
        <Item flex>
          <Menu
            options={getOptions()}
            contentBgColor={themeColors.primary_header}
          >
            <Row spacing={0} alignItems="center">
              <Item flex style={{ paddingRight: '10px' }}>
                <Avatar name={user.username} />
              </Item>
              <Item xs alignItems="flex-start">
                <Row spacing={0} alignItems="flex-start">
                  <Item alignItems="flex-start" justify="flex-start">
                    <Text
                      fontWeight={600}
                      fontSize="14px"
                      align="left"
                      color={themeColors.keep_light}
                    >
                      {user.username}
                    </Text>
                  </Item>
                </Row>
              </Item>
              <Item flex>
                <ArrowDropDownIcon style={{ color: themeColors.keep_light }} />
              </Item>
            </Row>
          </Menu>
        </Item>
        {isSuperAdmin && !!domainConfig?.domain && domainConfigs.length !== 0 && (
          <Item flex style={{ paddingRight: '10px' }}>
            <Select
              width="150px"
              color={themeColors.funnel}
              textColor={themeColors.keep_light}
              value={domainConfig?.domain}
              options={domainConfigs
                .filter(el => user.domains.includes(el.domain))
                .map(el => ({ value: el.domain, label: el.name }))}
              onChange={value => {
                setDomain(value);
                history.push('/dashboard');
              }}
            />
          </Item>
        )}
        <Item flex>
          <ButtonSwitchLight type="button" onClick={() => toggleTheme()}>
            <Icon icon={isLightMode ? 'darkSwitch' : 'lightSwitch'} />
          </ButtonSwitchLight>
        </Item>
      </Row>
    </Container>
  );
};

ProfilNav.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  isLightMode: PropTypes.bool.isRequired
};

export default withRouter(ProfilNav);
